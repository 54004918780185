import React from 'react';
import { useJobs } from 'hooks/useJobs';
import Link from 'components/Link';
import { urls } from 'config/urls';
import LinkedinIcon from 'assets/icons/linkedin-logo.svg';
import InstagramIcon from 'assets/icons/instagram-logo.svg';
import EunoiaWhiteLogo from 'assets/icons/eunoia-logo-white.svg';
import EmailIcon from 'assets/icons/email-icon.svg';
import { useTranslation } from 'react-i18next';
import styles from './Footer.module.scss';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const jobs = useJobs();

  const renderPlaces = () => {
    return (
      <>
        <div className={styles.place}>
          {/* <BarcelonaIcon />
          <p className={styles.city}>Barcelona</p> */}
          <p className={styles.headquarters}>Barcelona Headquarters</p>
          <p className={styles.phone}>+34 623 589 554</p>
          <p>Gran Via de Carles III 98, 4-2</p>
          <p>08028 Barcelona</p>
          <p>{t('Footer.esp')}</p>
          <a
            className={styles.llegar}
            target="_blank"
            rel="noreferrer"
            href="https://g.page/Eunoia?share"
          >
            {t('Footer.llegar')}
          </a>
        </div>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <footer className={styles.wrapper}>
        <div className={styles.a}>
          <Link url={urls.home.link}>
            <EunoiaWhiteLogo className={styles.mainLogo} />
          </Link>
        </div>
        <div className={styles.b}>
          <p className={styles.title}>Eunoia Digital</p>
          <p className={styles.quote}>{t('Footer.caption')}</p>
          <div className={styles.socialContainer}>
            <Link url="https://www.instagram.com/eunoiadigital/">
              <InstagramIcon />
            </Link>
            <Link url="https://www.linkedin.com/company/eunoiadigital/">
              <LinkedinIcon />
            </Link>
            <Link url="mailto:info@eunoia.es">
              <EmailIcon />
            </Link>
          </div>
        </div>
        <div className={styles.c}>
          <ul className={styles.linksWrapper}>
            <li>
              <Link url={urls.servicios.link}>{t('urls.servicios')}</Link>
            </li>
            <li>
              <Link url={urls.nosotros.link}>{t('urls.nosotros')}</Link>
            </li>
            {jobs.length !== 0 ? (
              <li>
                <Link url={urls.trabaja.link}>{t('urls.trabaja')}</Link>
              </li>
            ) : null}
            <li>
              <Link url={urls.proyectos.link}>{t('urls.proyectos')}</Link>
            </li>
            {i18n.language === 'es' ? (
              <li>
                <Link url={urls.blog.link}>{t('urls.blog')}</Link>
              </li>
            ) : null}
            <li>
              <Link url={urls.contacto.link}>{t('urls.contacto')}</Link>
            </li>
          </ul>
        </div>
        <div className={styles.d}>{renderPlaces()}</div>
        <div className={styles.low}>
          <Link url={urls.legal.link}>{t('urls.legal')}</Link>
          <span>|</span>
          <Link url={urls.cookies.link}>{t('urls.cookies')}</Link>
          <span>|</span>
          <Link url={urls.redessociales.link}>{t('urls.redessociales')}</Link>
          <span>|</span>
          <Link url={urls.privacidad.link}>{t('urls.privacidad')}</Link>
          <span>|</span>
          <small>Ⓒ 2023 Eunoia Digital</small>
        </div>
      </footer>
    </div>
  );
};

export default React.memo(Footer);
