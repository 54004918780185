import { urls } from 'config/urls';

import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import styles from './CookiesPopup.module.scss';

export default function CookiesPopup(props) {
  const { onAccept, onDeny, onAcceptSelection, setCheckboxes, checkboxes } =
    props;

  const { t } = useTranslation();

  const handleCheckboxChange = event => {
    const { name, checked } = event.target;
    setCheckboxes({ ...checkboxes, [name]: checked });
  };
  return (
    <div className={styles.container}>
      <p>
        {t('CookiesPopup.p1')}
        <Link href={urls.cookies.link}>
          <a className={styles.more}> aquí.</a>
        </Link>
      </p>

      <div className={styles.actions}>
        <button type="button" onClick={onDeny}>
          {t('CookiesPopup.deny')}
        </button>
        <button type="button" onClick={onAcceptSelection}>
          {t('CookiesPopup.selection')}
        </button>
        <button type="button" onClick={onAccept}>
          {t('CookiesPopup.accept')}
        </button>
      </div>
      <div className={styles.checkBoxcontainer}>
        <div className={styles.checkBoxCookie}>
          {Object.entries(checkboxes).map(([name, isChecked], index) => (
            <div key={name} className={styles.checkboxWith}>
              <label htmlFor={name}>
                <input
                  type="checkbox"
                  name={name}
                  id={name}
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  disabled={index === 0} // Disable the first checkbox
                />
                {t(`CookiesPopup.${name}`)}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
