import Head from 'next/head';
import { SEOConstants } from 'config/constants';
import propTypes from 'prop-types';

export default function HeadLayout(props) {
  const {
    title,
    keywords,
    url,
    author,
    robots,
    copyright,
    ogType,
    description,
    image,
    children
  } = props;
  return (
    <Head>
      <title>{title}</title>
      {/* Common */}
      <meta charSet="utf-8" />
      <meta name="author" content={author} />
      <meta name="copyright" content={copyright} />
      <meta name="robots" content={robots} />
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />

      {/* Open Graph */}
      <meta property="og:type" content={ogType} />
      <meta property="og:title" name="title" content={title} />
      <meta
        property="og:description"
        name="description"
        content={description}
      />
      <meta property="og:image" name="image" content={image} />
      <meta property="og:url" content={url} />
      {/*  Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />
      <link rel="canonical" href={url} />
      {children}
    </Head>
  );
}

HeadLayout.defaultProps = {
  title: SEOConstants.title,
  keywords: SEOConstants.keywords,
  author: SEOConstants.author,
  robots: SEOConstants.robots,
  copyright: SEOConstants.copyright,
  description: SEOConstants.description,
  url: SEOConstants.url,
  image: SEOConstants.image
};

HeadLayout.propTypes = {
  title: propTypes.string,
  keywords: propTypes.string,
  copyright: propTypes.string,
  url: propTypes.string,
  description: propTypes.string,
  author: propTypes.string,
  robots: propTypes.string,
  image: propTypes.string
};

// FILL:  title, url, description, image
