import CookiesPopup from 'components/CookiesPopup/CookiesPopup';
import Footer from 'components/Footer';
import NavigationMenu from 'components/NavigationMenu';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { GTM_TRACKING_ID } from 'services/gtm';

import Script from 'next/script';
import { GA_TRACKING_ID } from 'services/gtag';
import styles from './AppLayout.module.scss';

export default function AppLayout({ children }) {
  const [checkboxes, setCheckboxes] = useState({
    Necessary: true,
    Preferences: false,
    Statistics: false,
    Marketing: false
  });
  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  const [showPopup, setShowPopup] = useState(true);

  const NotShowPopup = () => {
    localStorage.setItem('showPopupCookies', 'false');
    setShowPopup(false);
  };

  const handleCookiesAccept = () => {
    setCookiesAccepted(true);
    NotShowPopup();
    localStorage.setItem('cookiesAccepted', 'true');
    localStorage.setItem('cookiesManaged', 'true');
  };

  // Como solo tenemos cookies de Stadistics, si las selecciona
  // y le da a aceptar seleccion, cargamos los pixels, sino
  // no
  const handleCookiesAcceptSelection = () => {
    if (checkboxes.Statistics) {
      setCookiesAccepted(true);
      NotShowPopup();
      localStorage.setItem('cookiesAccepted', 'true');
    } else {
      setCookiesAccepted(false);
      NotShowPopup();
      localStorage.setItem('cookiesAccepted', 'false');
    }
    localStorage.setItem('cookiesManaged', 'true');
  };

  const handleCookiesDeny = () => {
    // Eliminar todas las cookies
    const cookies = document.cookie.split(';');
    const domain = window.location.hostname.split('.').slice(-2).join('.');

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}; path=/;`;
    }

    // Eliminar cookies específicas
    document.cookie =
      '_ga_RFMPET1NWQ=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    document.cookie =
      '_gat_gtag_UA_135649034_1=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    NotShowPopup();
    localStorage.setItem('cookiesAccepted', 'false');
    localStorage.setItem('cookiesManaged', 'true');
  };

  // Al cargar el componente, revisamos si el showPopUpCookies esta a true anteriormente
  // Si esta a true, se ponen como true y no sale el popup, y al inreves igual.
  useEffect(() => {
    const showPopupCookies = localStorage.getItem('showPopupCookies');
    if (showPopupCookies === 'false') {
      setShowPopup(false);
    } else if (showPopupCookies === 'true') {
      setShowPopup(true);
    } else if (showPopupCookies === null) setShowPopup(true);
  }, [showPopup]);

  // Al cargar el componente, revisamos si ya se aceptaron las cookies anteriormente
  // Si esta a true, se ponen como true y no sale el popup, y al inreves igual.
  useEffect(() => {
    const cookiesPreviouslyAccepted = localStorage.getItem('cookiesAccepted');
    if (cookiesPreviouslyAccepted === 'true' && !cookiesAccepted) {
      NotShowPopup();
      setCookiesAccepted(true);
    } else if (cookiesPreviouslyAccepted === 'false' && cookiesAccepted) {
      setCookiesAccepted(false);
      NotShowPopup();
    }
  }, [cookiesAccepted]);

  const DEBUG = false;

  return (
    <>
      {cookiesAccepted && (
        <>
          <Script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
          />
          <Script
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${GA_TRACKING_ID}', {
              page_path: window.location.pathname,
              'anonymize_ip': true
            });
          `
            }}
          />
          {/* Google Tag Manager */}
          <Script
            async
            src={`https://www.googletagmanager.com/gtm.js?id=${GTM_TRACKING_ID}`}
          />
        </>
      )}

      <NavigationMenu />

      <main className={classNames(styles.main, { [styles.debug]: DEBUG })}>
        {children}
      </main>
      {showPopup && (
        <div className={styles.cookieswrapper}>
          <CookiesPopup
            onAccept={() => handleCookiesAccept()}
            onDeny={() => handleCookiesDeny()}
            onAcceptSelection={() => handleCookiesAcceptSelection()}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
          />
        </div>
      )}
      <Footer />
    </>
  );
}
