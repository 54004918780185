import { useState, useEffect } from 'react';
import { CMSConstants } from 'config/constants';
import { useTranslation } from 'react-i18next';

export const useJobs = () => {
  const { i18n } = useTranslation();

  const [jobs, setJobs] = useState([]);
  const currentLanguage = i18n.language.substring(0, 2);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(
        `${CMSConstants.BASE_URL}/job-offers?_locale=${currentLanguage}`
      );
      if (res.status === 200) {
        const resJobs = await res.json();
        setJobs(resJobs);
      }
    };
    fetchData();
  }, [currentLanguage]);

  return jobs;
};
export default useJobs;
