import NextLink from 'next/link';
import propTypes from 'prop-types';

export default function Link(props) {
  const { url, onClick, children, innerStyle } = props;

  return (
    <NextLink href={url}>
      <a
        tabIndex={0}
        role="button"
        aria-label={children.toString()}
        onClick={onClick}
        onKeyDown={onClick}
        className={innerStyle}
      >
        {children}
      </a>
    </NextLink>
  );
}

Link.propTypes = {
  url: propTypes.string,
  onClick: propTypes.func,
  innerStyle: propTypes.string
};

Link.defaultProps = {
  url: '',
  onClick: () => {},
  innerStyle: ''
};
