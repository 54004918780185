import React, { useEffect, useState } from 'react';
import { urls } from 'config/urls';
import Link from 'components/Link';
import EunoiaTitle from 'assets/icons/eunoia-digital-title.svg';
import BurgerIcon from 'assets/icons/NavigationMenu-burger.svg';
import CloseIcon from 'assets/icons/NavigationMenu-close.svg';
import ArrowRight from 'assets/icons/arrow_right_thin.svg';
import EunoiaSmile from 'assets/icons/eunoia-logo-smile-blue.svg';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useJobs } from 'hooks/useJobs';
import LanguageDropdown from 'components/LanguageDropdown';
import styles from './NavigationMenu.module.scss';

const NavigationMenu = () => {
  const MOBILE_THRESHOLD = 1023;
  const [showMobile, setShowMobile] = useState(false);
  const jobs = useJobs();

  const toggleMobileButton = () => {
    if (showMobile) {
      document.body.style.position = null;
      setShowMobile(false);
    } else {
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      setShowMobile(true);
    }
  };

  const handleClickMobile = () => {
    document.body.style.position = null;
    setShowMobile(false);
  };

  const [scrollMenu, setScrollMenu] = useState({
    visible: true,
    prevPosition: 0
  });
  const [currentDimensions, setCurrentDimensions] = useState({
    h: 0,
    w: 0
  });
  const getNavPosition = () => {
    if (!scrollMenu.visible) return { top: '-150px' };
    return { top: '0px' };
  };

  useEffect(() => {
    if (currentDimensions.w < MOBILE_THRESHOLD) {
      if (showMobile) {
        document.body.style.position = 'fixed';
      } else {
        document.body.style.position = null;
      }
    } else {
      document.body.style.position = null;
    }
  }, [currentDimensions]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setCurrentDimensions({
        h: window.innerHeight,
        w: window.innerWidth
      });
    });
    return () => {
      document.body.style.position = null;
      window.removeEventListener('resize', () => {});
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const prevScrollpos = scrollMenu.prevPosition;
      const currentScrollPos = window.pageYOffset;
      const visible =
        prevScrollpos > currentScrollPos ||
        currentScrollPos < 100 ||
        showMobile;

      setScrollMenu({
        prevPosition: currentScrollPos,
        visible
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollMenu.prevPosition]);

  const LinksArray = props => {
    const { mobileEventClick } = props;
    const { t, i18n } = useTranslation();
    return (
      <ul className={styles.links}>
        <li>
          <Link onClick={mobileEventClick} url={urls.servicios.link}>
            <span>{t('urls.servicios')}</span>
            <ArrowRight />
          </Link>
        </li>
        <li>
          <Link onClick={mobileEventClick} url={urls.nosotros.link}>
            <span>{t('urls.nosotros')}</span>
            <ArrowRight />
          </Link>
        </li>
        {jobs && jobs.length !== 0 ? (
          <li>
            <Link onClick={mobileEventClick} url={urls.trabaja.link}>
              <span>{t('urls.trabaja')}</span>
              <ArrowRight />
            </Link>
          </li>
        ) : null}

        <li>
          <Link onClick={mobileEventClick} url={urls.proyectos.link}>
            <span>{t('urls.proyectos')}</span>
            <ArrowRight />
          </Link>
        </li>
        {i18n.language === 'es' ? (
          <li>
            <Link onClick={mobileEventClick} url={urls.blog.link}>
              <span>{t('urls.blog')}</span>
              <ArrowRight />
            </Link>
          </li>
        ) : null}
        <li>
          <Link onClick={mobileEventClick} url={urls.contacto.link}>
            <span>{t('urls.contacto')}</span>
            <ArrowRight />
          </Link>
        </li>
      </ul>
    );
  };
  LinksArray.defaultProps = { mobileEventClick: () => {} };

  return (
    <nav style={getNavPosition()} className={styles.container}>
      <div className={styles.navigationHead}>
        <Link
          onClick={handleClickMobile}
          innerStyle={styles.homeLink}
          url={urls.home.link}
        >
          {/* <EunoiaLogo className={styles.logo} /> */}
          <EunoiaTitle className={styles.title} />
        </Link>
        <LinksArray />
        <div className={styles.languageDropdownContainer}>
          <LanguageDropdown />
        </div>
        <button
          onClick={toggleMobileButton}
          type="button"
          className={styles.mobileButton}
        >
          {showMobile ? <CloseIcon /> : <BurgerIcon />}
        </button>
      </div>
      <div
        className={classNames(styles.mobileDropdown, {
          [styles.show]: showMobile
        })}
      >
        <LinksArray mobileEventClick={handleClickMobile} />
        <div className={styles.smileLogo}>
          <EunoiaSmile />
        </div>
        <div className={styles.languageDropdownContainer}>
          <LanguageDropdown />
        </div>
      </div>
    </nav>
  );
};

export default React.memo(NavigationMenu);
