const urls = {
  home: { link: '/' },
  servicios: { link: '/servicios', label: 'Servicios' },
  careers: {
    link: '/careers',
    label: 'Careers'
  },
  design: {
    link: '/servicios/diseno-prototipado',
    label: 'Diseño y prototipado'
  },
  productos: {
    link: '/servicios/desarrollo-productos-digitales',
    label: 'Desarrollo de productos digitales'
  },
  marketing: {
    link: '/servicios/servicio-marketing-digital',
    label: 'Servicio de Marketing Digital'
  },
  implant: { link: '/servicios/servicio-implant', label: 'Servicio Implant' },
  nosotros: { link: '/nosotros', label: 'Sobre Nosotros' },
  trabaja: { link: '/jobs', label: 'Trabaja con nosotros' },
  oferta: { link: '/oferta', label: 'Oferta de trabajo' },
  proyectos: { link: '/case-studies', label: 'Casos de Éxito' },
  'case-elecsum': {
    link: '/case-studies/elecsum',
    label: 'Elecsum'
  },
  'case-smart-money': {
    link: '/case-studies/smart-money',
    label: 'Smart Money'
  },
  'case-mundo-deportivo': {
    link: '/case-studies/mundo-deportivo',
    label: 'Mundo Deportivo'
  },
  'case-six-senses': {
    link: '/case-studies/six-senses',
    label: 'Six Senses'
  },
  'case-manvert': {
    link: '/case-studies/manvert',
    label: 'Manvert'
  },
  'case-inibsa': {
    link: '/case-studies/inibsa',
    label: 'Inibsa'
  },
  blog: { link: '/blog', label: 'Blog' },
  contacto: { link: '/contacto', label: 'Contacto' },
  privacidad: { link: '/politica-privacidad', label: 'Política de Privacidad' },
  redessociales: {
    link: '/redes-sociales',
    label: 'Política de Redes Sociales'
  },
  cookies: { link: '/politica-cookies', label: 'Política de Cookies' },
  legal: { link: '/aviso-legal', label: 'Aviso Legal' }
};

const endpoints = {};

export { urls, endpoints };
