import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './LanguageDropdown.module.scss';

const LanguageDropdown = () => {
  const languages = ['ca', 'es', 'en'];
  const { i18n } = useTranslation();
  const [languageSelected, setLanguageSelected] = useState('es');

  useEffect(() => {
    setLanguageSelected(i18n.language.substring(0, 2));
  }, []);

  const handleLanguageClick = value => {
    i18n.changeLanguage(value);
    setLanguageSelected(value);
  };

  return (
    <div className={styles.container}>
      {languages.map(lang => {
        return (
          <button
            key={`language${lang}`}
            onClick={() => handleLanguageClick(lang)}
            type="button"
            className={classNames(styles.language, {
              [styles.selected]: lang === languageSelected
            })}
          >
            {lang}
          </button>
        );
      })}
    </div>
  );
};

export default React.memo(LanguageDropdown);
